$andes-theme: 'mercadolibre' !default;

@import '~@andes/common/variables';
@import '~@andes/typography/index';
@import '../../styles/common';

#root-app {
  height: 100%;
}

#error {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ui-empty-state {
  display: flex;
  height: 100%;
  flex: 1;
  flex-flow: column wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
